import React from "react";
import Icon from "../../Icons/Icon";
import LabelField from "../atoms/LabelField";
import Link from "../atoms/Link";
import { Nav } from "react-bootstrap";

interface HeaderProps {
	headerName?: string;
	headerClassName?: string;
}

const Header: React.FC<HeaderProps> = ({ headerName, headerClassName }) => {
  return (
    <>
    <div
        className={`pb-4 ${"headerWrapper"}`}
      >

    <div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-end">
						<Link href="/">
							<Icon name="Logo" width="13vw" height="2vw" isIcon={true} />
						</Link>
                     </div>
                     <div className="pe-5 me-4">
						<LabelField
							title={headerName}
							className={`colorPrimary poppins-semibold pe-5 me-5 ${headerClassName}`}
						/>
					</div>
                    <div className="d-inline-flex align-items-center py-2">
						<Nav>
                            <span className="cursor-pointer secondaryColorHoverEffect me-3">
                            </span>
							<div className=" d-flex align-items-center mb-3 mb-lg-0 me-lg-3">
                             </div>
                        </Nav>
                    </div>



    </div>
</div>
    </>
  )
}

export default Header