import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as ROUTE from "../routes/RouteConstants";
import NewRootLayout from '../components/layouts/RootLayout';
import ManageTranslations from '../components/Translations/Organism/ManageTranslations';
import DashBoard from '../components/DashBoard/Index';
export const Routes = createBrowserRouter([
    
    {
        path: "/",
        element: <NewRootLayout />,
        children: [
            { path: '/', element:<DashBoard/>},
            { path: '/manage-translations', element:<ManageTranslations/>},
            { path: '/dashboard', element:<DashBoard/>},
        ]
    }
]);