import React,{useState,useEffect} from 'react'
import ManageTranslationsTable from '../Molecules/ManageTranslationsTable';
import CustomNotify from "../../atoms/CustomNotify";
interface Translation {
  id: number;
  translation: string;
  text: string;
  isEditing?: boolean;
}
export interface LanguageData {
  [key: string]: {
    [key: string]: Translation;
  };
}
interface Language {
  id: number;
  name: string;
  key: string;
}
const  ManageTranslations = () =>{
  const [languageLinkedData, setLanguageLinkedData] = useState<LanguageData>({});
  const [languageDropDown, setLanguageDropDown] = useState<Language[]>();
  const [language, setLanguage] = useState("EN");
  useEffect(()=>{
    getLocalTarget();
  },[])
  const getLocalTarget = async () => {
    type TranslationData = {
      id: number;
      translation: string;
      text: string;
      isEditing?: boolean; // Optional, since it's added later
    };
    
    type Translations = {
      [key: string]: { [translationKey: string]: TranslationData };
    };
    
    // Use your response object
    const languages = [
      {
        id: 1,
        name: "English",
        key: "EN",
        created_at: "2024-08-13T13:41:31.000000Z",
        updated_at: "2024-08-13T13:41:31.000000Z",
      },
      {
        id: 2,
        name: "Dutch",
        key: "NL",
        created_at: "2024-08-19T18:56:59.000000Z",
        updated_at: "2024-08-19T18:56:59.000000Z",
      },
      {
        id: 3,
        name: "French",
        key: "FR",
        created_at: "2024-08-19T18:56:59.000000Z",
        updated_at: "2024-08-19T18:56:59.000000Z",
      }
    ];
    
    const translations: Translations = {
      EN: {
        1118: { id: 1118, translation: "Location", text: "Location" },
        1119: { id: 1119, translation: "Project", text: "Project" },
        1120: { id: 1120, translation: "Cost center", text: "Cost center" },
        1127: { id: 1127, translation: "Filter", text: "Filter" },
        1128: { id: 1128, translation: "QR Code", text: "QR code" },
        1129: { id: 1129, translation: "Employee scheduling", text: "Employee scheduling" },
        1130: { id: 1130, translation: "Employee", text: "Employee" },
        1131: { id: 1131, translation: "Status", text: "Status" },
        1132: { id: 1132, translation: "Function", text: "Function" }
      }
    };
    
    const response = {
      languages: languages,
      translations: translations,
    };
    
    console.log(response);
    console.log(response.translations);
    
    // Initialize language data
    const initialLanguageData: { [key: string]: { [key: string]: TranslationData } } = {};
    
    // Iterate through the response
    Object.keys(response.translations).forEach((languageKey) => {
      initialLanguageData[languageKey] = {};
      
      Object.keys(response.translations[languageKey]).forEach((translationKey) => {
        initialLanguageData[languageKey][translationKey] = {
          ...response.translations[languageKey as keyof Translations][translationKey],
          isEditing: false, // Add isEditing state
        };
      });
    });
    
    // Set the processed language data (this assumes you're using some state setter in React)
    setLanguageLinkedData(initialLanguageData);
  }
  const handleEditClick = async (key: any, type: string) => {
    if (type === "save") {
      if (languageLinkedData[language][key].translation === "") {
        
        CustomNotify({
          type: "warning",
          message: "Please provide translation",
        });
        return;
      }
      // const response = await ApiCall.service(
      //   SAVE_TRANSLATION,
      //   "POST",
      //   {
      //     translation: languageData[language][key].translation,
      //     key: key,
      //     appName: searchData.appName,
      //   },
      //   false,
      //   CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      // );

      // if (response.status === 200) {
      //   CustomNotify({
      //     type: "success",
      //     message: t(response.message) || t(response.msg),
      //   });
      // } else {
      //   CustomNotify({
      //     type: "error",
      //     message: t(response.message) || t(response.msg),
      //   });
      // }
    }
    setLanguageLinkedData((prevLanguageLinkedData) => {
      const newLanguageData = {
        ...prevLanguageLinkedData,
        [language]: {
          ...prevLanguageLinkedData[language],
          [key]: {
            ...prevLanguageLinkedData[language][key],
            isEditing: !prevLanguageLinkedData[language][key].isEditing,
          },
        },
      };

      return newLanguageData;
    });
   };

  return (
    <>
    <ManageTranslationsTable 
      languageLinkedData         = {languageLinkedData}
      setLanguageLinkedData      = {setLanguageLinkedData}
      handleEditClick            = {handleEditClick}
      getLocalTarget             = {getLocalTarget}
      language                   = {language}
    />
    </>
  )
}

export default ManageTranslations;