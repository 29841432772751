import React, { useEffect, useState } from "react";
//import { ApiCall } from "../../../services/ApiServices";
import Header  from "../../layouts/Header";
import Pagination from "../../atoms/Pagination";

import { Link } from "react-router-dom";



import {
  MYMMO_MOBILE_APP,
  MYMMO_WEB,
  MAX_FILE_SIZE,

} from "../../../Constants";
// import {
//   LOCALE_TARGETS,
//   LOCALE_TARGETS_SEARCH,
//   SAVE_TRANSLATION,
//   UPDATE_TRANSLATIONS,
// } from "../../../routes/ApiEndpoints";
// import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import CSVReader from "../../molecules/CSVReader";
import { LabelWithInputField } from "../../molecules/LabelWithInputField";

// import { useSelector } from "react-redux";

import Icon from "../../../Icons/Icon";
import ResetBtn from "../../atoms/ResetBtn";
import SearchBtn from "../../atoms/SearchBtn";
import SelectWithSearch from "../../atoms/SelectWithSearch";
import InputTextfield from "../../atoms/InputTextField";
import BackLink from "../../atoms/BackLink";
import OverFlowWrapper from "../../atoms/OverFlowWrapper";
import { LanguageData } from '../Organism/ManageTranslations';
interface Translation {
  id: number;
  translation: string;
  text: string;
  isEditing: boolean;
}



interface SearchProps {
  string: string;
  appName: string;
}

const options = [
  { value: MYMMO_MOBILE_APP, label: "MYMMO MOBILE APP" },
  { value: MYMMO_WEB, label: "MYMMO WEB " },
  // { value: "option3", label: "Option 3" },
];
const key = 0;


interface ManageTranslationsTableProps {
  languageLinkedData: LanguageData;
  setLanguageLinkedData: React.Dispatch<React.SetStateAction<LanguageData>>;
  handleEditClick:(key: any, type: string)=>void
  getLocalTarget:()=>void
  language:string
}
const ManageTranslationsTable: React.FC<ManageTranslationsTableProps> = ({ languageLinkedData, setLanguageLinkedData,handleEditClick,getLocalTarget,language }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state
  const [searchData, setSearchData] = useState<SearchProps>({
    string: "",
    appName: MYMMO_MOBILE_APP,
  });

  localStorage.translationStrings =
    localStorage.translationStrings || JSON.stringify([]);

  /**
   * Translation update in languageData
   * @param translationKey
   * @returns
   */
  const handleInputChange =
    (translationKey: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      setLanguageLinkedData((prevLanguageLinkedData) => {
        const newLanguageData = {
          ...prevLanguageLinkedData,
          [language]: {
            ...prevLanguageLinkedData[language],
            [translationKey]: {
              ...prevLanguageLinkedData[language][translationKey],
              translation: newValue,
            },
          },
        };

        return newLanguageData;
      });
    };

  /**
   * Page handler
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  let itemsPerPage = 5;
  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const entriesArray =
    languageLinkedData[language] &&
    Object.values(languageLinkedData[language]).sort((a, b) =>
      a.text.localeCompare(b.text, undefined, { sensitivity: "base" })
    );
  const currentItems =
  languageLinkedData[language] &&
    entriesArray.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages =
  languageLinkedData[language] &&
    Math.ceil(Object.keys(languageLinkedData[language]).length / itemsPerPage);

  let index = 0;

  const importFromCSV = async (importedData: any, file: any) => {
    if (!importedData || importedData.length === 0) {
      setErrorMsg("Please select a CSV file to upload.");
      return;
    }

    if (file.type !== "text/csv") {
      setErrorMsg(
        "Invalid file format, pleases upload only .csv file format"
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than 10MB.");
      return;
    }

    const importedHeaders = importedData.data[0];

    if (!Object.keys(languageLinkedData).includes(importedHeaders[2])) {
      setErrorMsg("Imported CSV headers do not match the expected format");
      return;
    }

    const missingDeveloperStrings: string[] = [];

    importedData.data.slice(1).forEach((row: any) => {
      const text = row[1];

      if (!text) {
        setErrorMsg("Empty developer string found in imported data.");
        return;
      }

      const matchingLanguage = Object.keys(languageLinkedData).find((language) =>
        Object.values(languageLinkedData[importedHeaders[2]]).some(
          (translation) => translation.text === text
        )
      );

      if (!matchingLanguage) {
        missingDeveloperStrings.push(text);
        return; // Skip further processing for this row
      }

      let id = NaN;
      if (row.length > 1) {
        for (const data in languageLinkedData[importedHeaders[2]]) {
          if (
            data &&
            languageLinkedData[importedHeaders[2]][data].text === text.toString()
          ) {
            id = languageLinkedData[importedHeaders[2]][data].id;
          }
        }

        const translation: Translation = {
          id: id,
          translation: row[index + 2],
          text,
          isEditing: false,
        };

        if (
          languageLinkedData &&
          languageLinkedData[importedHeaders[2]] &&
          languageLinkedData[importedHeaders[2]][translation.id]
        ) {
          languageLinkedData[importedHeaders[2]][translation.id] = translation;

          const updatedLanguageData = {
            ...languageLinkedData,
            [importedHeaders[2]]: {
              ...languageLinkedData[importedHeaders[2]],
              [translation.id]: translation,
            },
          };

          // Update the state with the new data
          setLanguageLinkedData(updatedLanguageData);
        }
      }
    });

    setErrorMsg("");

    // let response = await ApiCall.service(
    //   UPDATE_TRANSLATIONS,
    //   "POST",
    //   {
    //     translations: languageData[importedHeaders[2]],
    //   },
    //   true,
    //   CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    // );
    // if (response.status === 200) {
    //   setLanguage("EN");
    //   CustomNotify({
    //     type: "success",
    //     message: t(response.message) || t(response.msg),
    //   });
    //   setKey((prevKey) => prevKey + 1); // Update the key to force re-render
    // }
  };

  const getLocaleStrings = async () => {
    // const response = await ApiCall.service(
    //   LOCALE_TARGETS_SEARCH,
    //   "POST",
    //   searchData,
    //   true,
    //   CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    // );
    // if (response.status === 200) {
    //   setLanguageData(response.translations);
    // }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    await getLocaleStrings();
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      string: "",
      appName: MYMMO_MOBILE_APP,
    });
    setCurrentPage(1);
    getLocalTarget();
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption: any) => {
    setSearchData((prevData) => ({
      ...prevData,
      appName: selectedOption.value, // Update appName with selected option
    }));
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Translation",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div>
      <Header
          headerName={"Manage Translations"}
          headerClassName="myAccountCardTitle"
        />

        <div className="search-bar pb-3">
          <div className="row">
            <BackLink backLink="/" />
          </div>
          <div className="row">
            <div className="col-8">
              <LabelWithInputField
                label=""
                name="string"
                handleChange={handleSearchChange}
                placeholder={"Search by developer strings"}
                value={searchData.string}
                labelClassName="mb-2 colorPrimary poppins-medium"
              />
            </div>
            <div className="col-4">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <SearchBtn handleSearchClick={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="managepagesBg mb-5">
          <div className="row">
            <div className="col-md-12">
              <CSVReader
                key={key}
                importFromCSV={importFromCSV}
                errorMsg={errorMsg}
              />
            </div>
          </div>
          <div className="row mb-3">
            {
              <div className="col-lg-3 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                <SelectWithSearch
                  options={options}
                  search={false}
                  value={options.find(
                    (option) => option.value === searchData.appName
                  )}
                  onChange={handleSelectChange}
                />

                {/* <select
                  value={language}
                  onChange={handleApplicationChange}
                  className="border-0 form-select rounded-3 px-3 py-1 field-shadow select-field"
                >
                  {languageDropDown &&
                    languageDropDown
                      .sort((a, b) => a?.name.localeCompare(b.name)) // Sort the languages array by name
                      .map((language) => (
                        <option key={language.key} value={language.key}>
                          {language.name}
                        </option>
                      ))}
                </select> */}
              </div>
              //  <AccessControl
              //   requiredPermissions={[
              //     {
              //       permission: "Translation",
              //       update: true,
              //       create: true,
              //     },
              //   ]}
              //   actions={true}
              //   strict={false}
              // >
              //   {currentItems && currentItems.length > 0 && (
              //     <ExportCSV languageData={languageData} language={language} />
              //   )}
              // </AccessControl>
            }
            <div className="col-6 ms-auto text-end">
              <Link to="/translation/link-app-language">
                <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                  {"Link language"}
                </div>
              </Link>
            </div>
          </div>

          <OverFlowWrapper className="table-responsive manageTranslationWrapper">
            <table className="table customTableProperties">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "42%" }}>{"Developer strings"}</th>
                  <th style={{ width: "42%" }}>{"Translations"}</th>
                  <th style={{ width: "15%" }}>{"Action"}</th>
                  {/* <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Translation",
                            update: true,
                          },
                        ]}
                      >
                        <th className="ps-4">{t("Action")}</th>
                      </AccessControl> */}
                </tr>
                <tr>
                  <td
                    className="emptyRow p-0"
                    style={{ height: "0.7vw" }}
                    colSpan={3}
                  ></td>
                </tr>
              </thead>
              <tbody>
                {currentItems && currentItems.length > 0 ? (
                  Object.values(currentItems).map((data, key) => (
                    <tr
                      key={key}
                      className="border-bottom box-shadow mb-3 align-middle"
                    >
                      <td
                        className="text-break"
                        data-label={"Developer String"}
                      >
                        {data.text}
                      </td>
                      <td className="text-break" data-label={"Translation"}>
                        <InputTextfield
                          value={data.translation}
                          isDisabled={!data.isEditing}
                          handleChange={handleInputChange(data.id)}
                        />
                      </td>
                      {/* <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Translation",
                                update: true,
                              },
                            ]}
                          > */}
                      <td>
                        <div>
                          {data.isEditing ? (
                            <>
                              <span
                                title={"Save"}
                                onClick={() => handleEditClick(data.id, "save")}
                                className="me-2 secondaryColorHoverEffect"
                              >
                                <Icon {...IconProps} name="SaveIcon" />
                              </span>
                              <span
                                title={"Cancel"}
                                onClick={() =>
                                  handleEditClick(data.id, "cancel")
                                }
                                className="secondaryColorHoverEffect"
                              >
                                <Icon
                                  isIcon={true}
                                  width="0.9vw"
                                  height="0.9vw"
                                  name="close"
                                />
                              </span>
                            </>
                          ) : (
                            <span
                              title={"Edit"}
                              onClick={() => handleEditClick(data.id, "edit")}
                              className="secondaryColorHoverEffect"
                            >
                              <Icon {...IconProps} name="EditIcon" />
                            </span>
                          )}
                        </div>
                      </td>
                      {/* </AccessControl> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="border text-danger text-center">
                      {"No records"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {totalPages > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
     
    </>
  );
};

export default ManageTranslationsTable;
