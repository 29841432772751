import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconWithTitle from "../molecules/IconWithTitle";
import { Overlay, Popover } from "react-bootstrap";
import LabelField from "../atoms/LabelField";
interface SidebarItem {
    name: string;
    title: string;
    path?: string;
    submenu?: { name: string; title: string; path: string }[];
  }
  
const sidebarItems: SidebarItem[] = [
    { name: "dashboard", title: "Dashboard", path: "/dashboard" },
];
  
const Sidebar: React.FC = () => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const [target, setTarget] = useState<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const handleItemClick = (
        event: React.MouseEvent<HTMLElement>,
        item: SidebarItem
      ) => {
        setActiveItem(item.name);
        if (item.submenu) {
          setTarget(event.currentTarget);
          setShow((prev) => !prev);
        } else {
          setShow(false);
          navigate(item.path || "/default-path");
        }
      };
    
    const renderSidebarItem = (item: SidebarItem, index: number) => {
        const isActive = item.name === activeItem;
        const itemClassName = isActive
          ? "sidebarItemWrapper active"
          : "sidebarItemWrapper";
    
        return (
          <div
            key={index}
            className={itemClassName}
            ref={item.name === "documentation" ? ref : null}
          >
            <span
              onClick={(event) => handleItemClick(event, item)}
              className="cursor-pointer"
            >
              <div
                className={`col-12 sidebarContent text-center sidebarBorder ${
                  isActive ? "active" : ""
                }`}
              >
                <IconWithTitle
                  name={item.name}
                  isIcon={true}
                  isTitle={true}
                  width="10vw"
                  height="2vw"
                  className="img-fluid mx-auto"
                  title={item.title}
                  labelClassName={`text-white sidebarTitle cursor-pointer ${
                    isActive ? "activeTitle" : ""
                  }`}
                />
              </div>
            </span>
          </div>
        );
      };
    
  return (
    <div className="sideBar">
      <div
        className="col-12 sidebarContent sidebarBorder"
        style={{ height: "6vw" }}
      >
        {sidebarItems.map(renderSidebarItem)}
        </div>
    </div>

  )
}

export default Sidebar