import React,{useEffect} from 'react'
import Tile from '../atoms/Tile';
import { useNavigate } from "react-router-dom";
import Header  from "../layouts/Header";
interface TileProps {
    name: string;
    title: string;
    path: string;

  }
  const tileItms: TileProps[] = [
    { name: "dashboard", title: "Manage Translations", path: "/manage-translations" },
];
function Index() {
    const navigate = useNavigate();
    const handlickTitle = (path:string) => {
        navigate(path || "/default-path");
    }
    useEffect(()=>{
        navigate('/dashboard')
    },[])
  return (
    <>
    <div>
     
      <Header
          headerName={"Manage Dashboard"}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-3">
            <div className="row">
                {tileItms.map(item => (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }} onClick={()=>handlickTitle(item.path)}>

                    <Tile text={item.title}/>
                </div>
                ))}
           
            </div>
        </div>
    </div>
    </>
  )
}

export default Index